import React, { } from "react"

const Title = ( {text, color, bold, withLine, marginTop = 0, marginBottom = 54, id="", goTo } ) => {
    
    const onClick = (id) => {
        console.log(id)
        const anchorComment = document.getElementById(id);
        console.log(anchorComment)
        if(anchorComment) {
            let rect = anchorComment.getBoundingClientRect();
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            console.log(scrollTop)
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: rect.top + scrollTop - 10
            });
        }
    }

    return (
        <section class={`title ${withLine ? 'with-line' : ''}`} id={id} style={{ marginTop: marginTop + "px", marginBottom: marginBottom + "px" }}>
            <div className="container">
                <div className="title-wrapper">
                    <h2 className={`${bold ? " bold" : ""} ${color == "blue" ? "blue" : ""}`}>{text}</h2>
                    {goTo &&
                        <span onClick={() => onClick(goTo)}>Zapisz się już teraz!</span>
                    }
                </div>
            </div>
        </section>
    )

}



export default Title;