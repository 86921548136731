import React, { useState, useEffect } from "react"

export default (props) => {
  let [ error, setError ] = useState( false );
  let [ load, setLoad ] = useState( false );
  let [ errorText, setErrorText ] = useState( false );

  useEffect(() => {
    if ( load ) {
        setLoad(false)
    }
  }, [ props.reset])

  useEffect(() => {
    if ( !load ) {
      setError(false);
      setErrorText(false);
      setLoad(true);
    }
  }, [load])

  useEffect(() => {
    if ( load ) {
        validate();
    }
  }, [props.forceValidate, props.value])

  const validate = () => {
        let val = props.value;

      // check if empty
      if ( props.required && val && val.length == 0) {
            setErrorText('To pole jest wymagane');
            setError(true);
            return false;
        } else {
            setError(false);
        }

        //check length 
        if ( props.required && val.length < props.minChar ) {
            setError(true);       
            setErrorText('Za mało znaków');
            return false;
        } else {
            setError(false);
        }


        //check format
        if ( props.required && props.regExp ) {
            
            let regExp = new RegExp(props.regExp);

            if(regExp.test(val)) {
                setError(false);
            } else {
                setError(true);       
                setErrorText('Zły format');
                return false;
            }
        }

        return true;
        
  }

  return (
    <div className={"input-wrap " + (props.class ? props.class + ' ' : '') + (props.type ? props.type + ' ' : '') + ( error ? 'error ' : '') }>
        {props.title &&
          <label for={ props.name }>{ props.title }{ props.required ? '*' : '' }</label>
        }
        <input id={ props.name } autoComplete="off" type={ props.type ? props.type : 'text' } placeholder={ props.placeholder } onChange={ (e) => props.setFunction(e.target.value) } onBlur={ (e) => validate() } value={ props.value } />
        { error && errorText && errorText.length > 0 && !props.disableErrorText &&
          <p className="error-text">
            { errorText }
          </p>
        }
    </div>
  )
}
